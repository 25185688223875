<template>
  <div class="bg">
    <el-row>
      <el-col :span="4">
        <router-link to="/product">
          <img class="logo" src="../assets/logo.gif" />
        </router-link>
      </el-col>
      <el-col :span="2" class="titlerow">
        <router-link to="/product" class="title">
          产品中心
        </router-link>
      </el-col>
      <el-col :span="2" class="titlerow">
        <router-link to="/support" class="title">
          服务支持
        </router-link>
      </el-col>
      <el-col :span="2" class="titlerow">
        <router-link to="/customer" class="title">
          客户案例
        </router-link>
      </el-col>
      <el-col :span="2" class="titlerow">
        <router-link to="/about" class="title">
          关于我们
        </router-link>
      </el-col>
      <el-col :span="8">&nbsp;</el-col>
      <el-col :span="4" class="titlerow">
        <router-link to="" class="title">
          登录
        </router-link>
      </el-col>
    </el-row>
  </div>
  <div>
      <router-view></router-view>
  </div>
  <el-row style="margin:20px"><el-col :span="24">&nbsp;</el-col></el-row>
  <div class="footer">
    <span style="color:white;margin: 20px;">
      本网站已在工信部备案，备案号为粤ICP备2024254058号-1<br/>
      ICP备案详情见
      <a href="https://beian.miit.gov.cn/" target="_blank"
      style="color:red;margin: 20px;text-decoration: none;">
        ICP/IP地址/域名信息备案
      </a>
    </span>
  </div>
</template>
<script>
  export default{
    name: 'FramePage',
    mounted() {
      // 设置标题
      this.$setTitle('广州金恩-首页');
    },
    methods:{
    }
  }
</script>
<style scoped>
.bg{
    margin: 0;
    height:150px;
    background-image: url('../assets/main.png');
}
.footer{
    margin: 0;
    height:50px;
    background-image: url('../assets/footer.png');
}

.titlerow{
  margin: 20px;
}
.title {
  font-size: 1.5em;
  color: white;
  text-decoration: none;
}
.logo{
  margin:20px;
  width:120px;
}

</style>