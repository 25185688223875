import { createRouter, createWebHistory } from 'vue-router'
import ProductPage from "./components/ProductPage.vue"
import SupportPage from "./components/SupportPage.vue"
import CustomerPage from "./components/CustomerPage.vue"
import AboutPage from "./components/AboutPage.vue"
import ProductCommunity from "./components/ProductCommunity.vue"
import ProductCharging from "./components/ProductCharging.vue"
import ProductFood from "./components/ProductFood.vue"
import ProductErp from "./components/ProductErp.vue"
const routes = [
  {  
    path: '/product',  
    name: 'ProductPage',  
    component: ProductPage,
    meta:{title:"产品中心"}
  },
  {  
    path: '/support',  
    name: 'SupportPage',  
    component: SupportPage,
    meta:{title:"服务支持"}
  },
  {  
    path: '/customer',  
    name: 'CustomerPage',  
    component: CustomerPage,
    meta:{title:"客户案例"}
  },
  {  
    path: '/about',  
    name: 'AboutPage',  
    component: AboutPage,
    meta:{title:"关于我们"}
  },
  {
    path: '/community',
    name:'ProductCommunity',
    component: ProductCommunity,
    meta:{title:"社区管理产品"}
  },
  {
    path: '/charging',
    name:'ProductCharging',
    component: ProductCharging,
    meta:{title:"电动车充电产品"}
  },
  {
    path: '/food',
    name:'ProductFood',
    component: ProductFood,
    meta:{title:"订餐服务产品"}
  },
  {
    path: '/erp',
    name:'ProductErp',
    component: ProductErp,
    meta:{title:"ERP产品"}
  },
  { path: '/', redirect: '/product' }
    // 你可以继续添加更多的路由规则  
]

const router = createRouter({  
    history: createWebHistory(process.env.BASE_URL),  
    routes  
})

// 全局前置守卫设置标题
router.beforeEach((to, from, next) => {
  // 根据路由设置标题
  if (to.meta.title) {
    document.title = "广州金恩-"+to.meta.title;
  }
  next();
})
export default router