<template>
  <div>
    <el-row style="margin:20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="2">
        <router-link to="/product" class="back">&lt;返回</router-link>
      </el-col>
      <el-col :span="20">
        <img src="../assets/community.png" class="image"/><br/>
        <el-text class="content">
        社区管理产品旨在为社区居民提供一个便捷、高效、安全的生活环境。
        该系统涵盖物业管理、居民互动、生活服务等多个方面，
        通过数字化手段实现信息共享、资源整合，提升社区治理水平。
        物业管理功能包括投诉报修、物业缴费、访客管理等服务，
        方便居民随时随地进行物业沟通与缴费。
        居民互动功能则提供邻里交流、活动组织、意见征集等平台，
        促进居民间的互动与沟通。
        生活服务功能则整合了周边商家、家政服务、医疗服务等资源，
        为居民提供一站式生活服务。社区管理系统致力于打造智慧社区，
        让居民享受更美好的生活。
        </el-text>
      </el-col>
    </el-row>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image{
  height:500px;
}
.back{
  text-decoration: none;
  font-size: 1.2em;
  color:blue
}

.content{
  text-align: left;
  font-size: 1.5em;
}

</style>
