<template>
  <div>
    <el-row style="margin:20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="2">
        <router-link to="/product" class="back">&lt;返回</router-link>
      </el-col>
      <el-col :span="20">
        <img src="../assets/food.png" class="image"/><br/>
        <el-text class="content">
        订餐服务产品是现代生活中不可或缺的便捷工具，
        它将美食与科技完美结合，为用户提供了一站式的餐饮解决方案。
        从多样化的餐厅选择到个性化的菜品推荐，
        从智能的订单处理到高效的配送服务，
        订餐服务产品无时无刻不在为用户提供着贴心、快捷、安全的用餐体验。
        用户只需轻点几下屏幕，便可轻松浏览附近的餐饮选项，
        根据自己的口味偏好和饮食需求，挑选心仪的美食。
        同时，订餐服务产品还提供了实时订单追踪、用户评价反馈等功能，
        让用户的用餐过程更加透明、放心。
        随着技术的不断创新和服务的持续优化，
        订餐服务产品将继续为人们的生活带来更多便利和惊喜。
        </el-text>
      </el-col>
    </el-row>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image{
  height:500px;
}
.back{
  text-decoration: none;
  font-size: 1.2em;
  color:blue
}
.content{
  text-align: left;
  font-size: 1.5em;
}
</style>
