<template>
  <FramePage ver="1.0"/>
</template>

<script>
import FramePage from './components/FramePage.vue'

export default {
  name: 'App',
  components: {
    FramePage
  },
  metaInfo:{
    title:"金恩科技发展有限公司"
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
