<template>
  <div>
    <el-row style="margin: 20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="24">
        <span style="text-align:center;font-size:1.5em;width:100%;color:blue">
          公司简介
        </span>
      </el-col>
    </el-row>
    <el-row style="margin: 20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="2"></el-col>
      <el-col :span="20" style="text-align:left;font-size:1.2em;">
        <span>
        &nbsp;&nbsp;&nbsp;&nbsp;广州金恩信息技术有限公司，成立于2011年05月20日，是一家致力于科技创新和智能解决方案的企业。我们专注于利用最新的技术，包括人工智能、大数据分析、云计算和物联网等，来开发创新的产品和服务，以满足市场的需求。<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;我们的团队由一群经验丰富的专业人士组成，他们在各自的领域拥有深厚的知识和技能。我们致力于为客户提供高质量、高效率的解决方案，帮助他们解决复杂的问题，提高业务效率和竞争力。<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;我们的业务涵盖了多个领域，包括智能制造、智能医疗、智慧城市、金融科技等。我们不仅提供定制化的解决方案，还为客户提供全方位的技术支持和售后服务，确保他们能够充分利用我们的技术和产品。<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;作为一家科技有限公司，我们深知技术创新的重要性，因此我们不断投入研发，推动技术的进步和应用的拓展。我们与多家研究机构和高校建立了紧密的合作关系，共同开展前沿技术的研究和应用。<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;我们坚持以人为本，以客户为中心，以质量为生命，以创新为动力。我们致力于成为科技领域的领导者，为客户提供最优质的产品和服务，为社会创造更大的价值。<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;总之，金恩科技有限公司是一家充满活力和创新的科技企业，我们愿意与客户、合作伙伴和社会各界携手合作，共同推动科技的发展，创造更美好的未来。
        </span>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>
    <el-row style="margin: 200px"><el-col :span="24">&nbsp;</el-col></el-row>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
