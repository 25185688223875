<template>
  <div>
    <el-row style="margin:20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="2">
        <router-link to="/product" class="back">&lt;返回</router-link>
      </el-col>
      <el-col :span="20">
        <img src="../assets/charging.png" class="image"/><br/>
        <el-text class="content">
        电动车充电产品，作为现代出行方式的绿色能源补给站，
        以其智能便捷、快速高效、安全可靠的特点，
        正逐渐成为城市交通的重要组成部分。
        目前市场上，从家用充电桩到公共充电桩，
        再到便携式充电器，各类产品层出不穷，
        满足了不同场景下用户的充电需求。
        家用充电桩以其智能调控、远程监控等功能，
        为用户提供了个性化的充电解决方案；
        公共充电桩则以其广泛分布、快速充电等优势，
        成为城市基础设施的重要组成部分；
        而便携式充电器则凭借其小巧轻便、随充随走的特性，
        解决了用户户外充电的燃眉之急。
        随着科技的不断进步和市场的日益成熟，
        电动车充电产品正朝着更加智能化、网络化、多样化的方向发展，
        为绿色出行注入了强大的动力。
        </el-text>
      </el-col>
    </el-row>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image{
  height:500px;
}

.back{
  text-decoration: none;
  font-size: 1.2em;
  color:blue
}
.content{
  text-align: left;
  font-size: 1.5em;
}
</style>
