<template>
  <div>
    <el-row style="margin: 20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="24">
        <span style="text-align:center;font-size:1.5em;width:100%;color:blue">
          客户案例
        </span>
      </el-col>
    </el-row>
    <el-row style="margin: 20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="6">
        <span class="title">广州市穗保安全押运有限公司</span><br/>
        <img src="../assets/sb.png" class="image"/>
      </el-col>
      <el-col :span="6">
        <span class="title">某政府机关单位</span><br/>
        <img src="../assets/gov.png" class="image"/>
      </el-col>
      <el-col :span="6">
        <span class="title">AI公司</span><br/>
        <img src="../assets/icon1.jpg" class="image"/>
      </el-col>
      <el-col :span="6">
        <span class="title">网络公司</span><br/>
        <img src="../assets/icon2.jpg" class="image"/>
      </el-col>
    </el-row>
    <el-row style="margin: 60px"><el-col :span="24">&nbsp;</el-col></el-row>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image{
  height:200px;
}
.title{
  margin:10px;
  font-size: 1.2em;
  color:blue;
}
</style>
