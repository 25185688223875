<template>
  <div>
    <el-row style="margin:20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="2">
        <router-link to="/product" class="back">&lt;返回</router-link>
      </el-col>
      <el-col :span="20">
        <img src="../assets/erp.png" class="image"/><br/>
        <el-text class="content">
        企业ERP（Enterprise Resource Planning）产品是一款集成管理软件，
        它将企业的各个业务流程紧密连接，实现信息共享和数据互通，
        全面提升企业的运营效率和管理水平。从采购管理到库存控制，
        从生产计划到销售跟踪，从财务管理到人力资源，
        ERP产品涵盖了企业运作的方方面面。
        它通过优化供应链，提高生产效率，降低成本，实现资源的最优配置。
        同时，ERP产品还提供了强大的数据分析功能，帮助企业实时监控业务状况，
        为决策提供科学依据。随着信息化建设的不断深入，
        ERP产品已经成为现代企业提升竞争力、实现可持续发展的关键利器。
        </el-text>
      </el-col>
    </el-row>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image{
  height:500px;
}
.back{
  text-decoration: none;
  font-size: 1.2em;
  color:blue
}


.content{
  text-align: left;
  font-size: 1.5em;
}

</style>
