<template>
  <div>
    <el-row class="margin"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="2"></el-col>
      <el-col :span="9">
        <router-link to="/community">
          <img src="../assets/community.png" class="image"/>
        </router-link><br/>
        <el-text class="content">
          社区管理产品为社区居民提供一个便捷、高效、安全的生活环境。
          <router-link to="/community" class="bluelink">详情</router-link>
        </el-text>
      </el-col>
      <el-col :span="2"></el-col>
      <el-col :span="9">
        <router-link to="/charging">
          <img src="../assets/charging.png" class="image"/>
        </router-link><br/>
        <el-text class="content">
          电动车充电产品，正逐渐成为城市交通的重要组成部分。
          <router-link to="/charging" class="bluelink">详情</router-link>
        </el-text>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>
    <el-row class="margin"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="2"></el-col>
      <el-col :span="9">
        <router-link to="/food">
          <img src="../assets/food.png" class="image"/>
        </router-link><br/>
        <el-text class="content">
        订餐服务产品是现代生活中不可或缺的便捷工具。
        <router-link to="/food" class="bluelink">详情</router-link>
      </el-text>
      </el-col>
      <el-col :span="2"></el-col>
      <el-col :span="9">
        <router-link to="/erp">
          <img src="../assets/erp.png" class="image"/>
        </router-link><br/>
        <el-text class="content">
        企业产品是一款集成管理软件，实现企业的信息共享和数据互通。
        <router-link to="/erp" class="bluelink">详情</router-link>
        </el-text>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>
    <el-row class="margin"><el-col :span="24">&nbsp;</el-col></el-row>
  </div>
  <div id="contact" style="text-align:center;">
      <span style="color:blue;font-size:1.2em;">
          联系我们
      </span><br />
      <el-row style="margin:5px"><el-col :span="24">&nbsp;</el-col></el-row>
      <span style="color:black;font-size: 1.0em;text-align: left;">
          广州金恩信息技术有限公司<br/>
          地址：广州市海珠区宝业路58号首层<br/>
          电话：020-87533155
      </span>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image{
  height:350px;
}

.content{
  text-align: left;
  font-size: 1.1em;
}
.bluelink{
  color:blue;
  text-decoration: none;
}

.margin{
  margin: 20px;
}
</style>
