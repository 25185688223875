<template>
  <div>
    <el-row style="margin:20px"><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="2"></el-col>
      <el-col :span="9">
        <span class="title">网络服务</span><br/>
        <el-row style="margin:10px"><el-col :span="24">&nbsp;</el-col></el-row>
        <img src="../assets/network.png" class="image"/><br/>
        <el-row style="margin:10px"><el-col :span="24">&nbsp;</el-col></el-row>
        <el-text class="content">
        我们专注于为社区或企业提供全面的网络解决方案，
        包括宽带接入、WIFI布设以及网络系统的完善和拓展。
        我们的专业团队将根据您的具体需求，为您量身定制最合适的网络架构，
        确保网络稳定、高效、安全。无论是提升办公效率，还是优化社区居民的上网体验，
        我们都致力于为您打造一个无缝、便捷的网络环境。
        </el-text>
      </el-col>
      <el-col :span="2"></el-col>
      <el-col :span="9">
        <span class="title">软件服务</span><br/>
        <el-row style="margin:10px"><el-col :span="24">&nbsp;</el-col></el-row>
        <img src="../assets/software.png" class="image"/><br/>
        <el-row style="margin:10px"><el-col :span="24">&nbsp;</el-col></el-row>
        <el-text class="content">
        我们专业提供小区或企业软件系统的全方位服务，
        包括搭建、安装、维护和运营。我们的技术团队拥有丰富的经验，
        能够根据您的需求，为您量身定制最适合的软件解决方案。
        我们承诺，无论在哪个环节，都会提供及时、高效、专业的服务，
        确保软件系统稳定、安全地运行，让您专注于核心业务，无需为技术问题分心。
      </el-text>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>
    <el-row style="margin:20px"><el-col :span="24">&nbsp;</el-col></el-row>
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image{
  height:300px;
}
.title{
  margin:10px;
  font-size: 1.5em;
  color:blue;
}
.content{
  text-align: left;
  font-size: 1.2em;
}
</style>
