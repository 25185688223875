import { createApp } from 'vue'
import App from './App.vue'
import routes from './router'
import ElementPlus from 'element-plus'
import axios from 'axios'
import 'element-plus/dist/index.css'
import './css/global.css'

const app=createApp(App)
app.config.globalProperties.$axios = axios
// 定义一个全局方法来设置标题
app.config.globalProperties.$setTitle = function(title) {
  document.title = title;
};

app.use(ElementPlus)
app.use(routes)
app.mount('#app')
